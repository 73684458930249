import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [{
		path: '/',
		name: 'home',
		component: () => import('@/views/HomeView.vue')
	}, {
		path: '/login',
		name: 'login',
		component: () => import('@/views/login.vue')
	}, {
		path: '/ShoppingCart',
		name: 'ShoppingCart',
		component: () => import('@/views/ShoppingCart.vue')
	},
	{
		path: '/settlement',
		name: 'settlement',
		component: () => import('@/views/settlement.vue')
	}, {
		path: '/detail/:id',
		name: 'detail',
		component: () => import('@/views/detail.vue')
	}, {
		path: '/404',
		component: () => import('@/views/404.vue'),
		hidden: true,
	}, {
		path: '/resetpassword',
		component: () => import('@/views/resetmima.vue'),
		hidden: true,
	},{
		path: '/meber',
		component: () => import('@/views/meber/index.vue'),
		children:[{
			path:'/',
			component:() => import('@/views/meber/userinfo.vue'),
		},{
			path:'/meber/order',
			component:() => import('@/views/meber/order.vue'),
		},{
			path:'/meber/orderDetail',
			component:() => import('@/views/meber/orderDetail.vue'),
		},{
			path:'/meber/address',
			component:() => import('@/views/meber/address.vue'),
		}]
	},
	{
		path: '*',
		redirect: '/404'
	}


]
const router = new VueRouter({
	// mode: 'history',
	scrollBehavior: () => ({
		y: 0
	}),

	routes: routes
})
export default router
