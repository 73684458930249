import axios from 'axios';
//const base_url = "https://apishop.islfx.com"//旧地址
// const base_url = "https://testmall.myintuser.com"//正在测试的网址
// const base_url = "https://mall-api.myintuser.com"//现在官网网址


// const base_url = "https://mall-demo-api.acegrady.com"//现在官网网址
const base_url = "https://mall-demo-api.acegrady.com"//现在官网网址
let base_url1 = 'https://mall-demo-api.acegrady.com'
    base_url1=base_url  //临时本地测试
const isProduction = !!(process.env.NODE_ENV === 'production');
axios.defaults.baseURL = isProduction ? base_url : base_url1;
axios.interceptors.request.use((config) => {
	//config.headers.token = 'token和router/index'
	 config.headers.token = localStorage.getItem('token');//线网
	//config.headers.token = 'bb75be95a8c17089e9765576d054051b';//本地
	config.headers.lang = localStorage.getItem('lang')||'en';
	return config
})
axios.interceptors.response.use((j) => {
	return j
},(err)=>{
	try{
		var errMsg = err.response.data.msg||err.response.data.message||err.message
		// console.log('aaa',err.response)
		vm.$message.error(errMsg)
		if(err.response.status==401||errMsg=='Token无效'||errMsg=='Token is incorrect'){
			localStorage.removeItem('token');
			setTimeout(()=>{
				vm.$router.replace('/')
			},200)
		}
		return Promise.reject(errMsg)
		throw Error(errMsg);
	}catch(e){
		//TODO handle the exception
		console.log(e)
	}
})

export default {
	GET(url, p) {
		return axios.get(url, {
			params: p
		})
	},
	POST(url, p) { 
		return axios.post(url, p)
	},
	PUT(url, p) {
		return axios.put(url, p)
	},
	DELETE(url, p) {
		return axios.delete(url, {
			data: p
		})
	}
}
