//日语
export default {

	tip: {

		fromSuccess: '提交成功'

	},

	login: {

		linkList: [{ name: '星際積分商城', url: '/' }, { name: '注册成為交易者', url: 'https://tw.fisg.com/register/trader?link_id=ct01egz0&referrer_id=v4w8jplv' }],

		registered: {

			text: '沒有帳戶？點擊注册>>',

			url: 'https://tw.fisg.com/register/trader?link_id=ct01egz0&referrer_id=v4w8jplv'

		},

		shouye: {

			url: '/'

		},

		forgot: '忘記密碼？',

		title: '帳戶登入',

		email: '郵箱',

		pwd: '密碼',

		denglu: '登入',

		mima: '忘記密碼',

		Reset: '重置密碼',

		p_email: '請輸入郵箱',

		p_pwd: '請輸入密碼',

		p_new_pwd: '請輸新入密碼',

		z_email: '請輸入正確的郵箱地址',

		long: '長度在6到18個字元',

		determine: '確認',

		Valid: '重置密碼申請已提交,請檢查您的郵箱查收重置連結,連結24小時內有效！',

		Help_reset: '輸入您注册的電子郵箱,我們會幫助您重置密碼。'

	},

	myhead: {

		loginName: '登入',
		// loginName:'登入',

		Personalcenter: '個人中心',

		placeholder: '請輸入…',

		myhead_a: '首頁',

		myhead_b: '交易',

		myhead_c: '平臺',

		myhead_d: '交易學院',

		myhead_e: '關於我們',

		myhead_f: '星際助力您',

		myhead_g: '成為最佳交易者',

		myhead_h: '開設真實帳戶',

		myhead_i: '開始類比帳戶',

		myhead_j: {

			text: '市場活動',

			url: 'https://www.interstellarfx.net/zh/trade-cashback'

		},

		myhead_k: '交易返現',

		myhead_l: '交易產品',

		myhead_m: '交易相關',

		myhead_n: '外匯學堂',

		myhead_o: '中秋積分獎勵活動',

		myhead_p: '星際公告',

		myhead_q: '市場新訊',

		myhead_r: '差價合約是複雜的金融交易產品，由於槓桿的特性，具有導致迅速虧損的高風險。大部分的散戶投資者在進行差價合約交易時是虧損的。用戶應充分了解差價合約的運作方式，以及衡量是否能承擔資金損失的高風險。',
		myhead_s: '公司',
		myhead_t: '市場',
		myhead_u: '報價',
		myhead_v: '分析與教育',
		myhead_v_a: '分析',
		myhead_v_b: '教育',
		myhead_w: '監管',
		myhead_x: '保險',
		myhead_y: '法令遵循',
		myhead_z: '最新動態',
		myhead_aa: '交易指南',
		myhead_bb: '常見交易術語',
		myhead_cc:'即時新聞',
		myhead_dd:'交易分析',
		myhead_ee:'外匯投資策略',
		myhead_ff:'活動贊助 & 社會責任',
	},

	Home: {

		Home_a: '星際積分商城',

		Home_b: '交易得積分,積分換豪禮！',

		Home_c: '交易積分兌換好禮,時尚數碼、精緻電器、頂級豪車等眾多好物隨時帶回家…',

		Home_d: '查看活動條款&條例適用',

		Home_e: '點擊',

		Home_f: '這裡',

		Home_g: '繽紛好禮,就等你來',

		Home_h: '沒有您想要的禮品？',

		Home_i: '歡迎您隨時推薦想要的商品給我們,我們將根據推薦禮品的受歡迎度補充商品…',

		Home_j: '禮品品牌：如小米、華為…',

		Home_k: '商品描述：如小米電動plus九號平衡車…',

		Home_l: '提交',

		Home_m: '積分禮品兌換規則',

		Home_n: '積分獲取管道',

		Home_o: '任何InterstellarFX客戶在我們的平臺交易貨幣對、貴金屬及能源產品,每交易1手,即可獲得1積分（持倉三分鐘以上訂單視為有效）。',

		Home_p: '禮品如何兌換',

		Home_q: '在積分商城提交兌換申請（須填寫姓名、聯繫方式、及交易帳號等資訊）,後臺將稽核您積分及近期有無違規交易行為,稽核通過後進行配送。',

		Home_r: '禮品配送時間',

		Home_s: '兌換申請稽核通過後15天內禮品將會進行配送,請注意查收,您也可聯系您專屬客服經理査詢配送進度。',

		Home_t: '商品售後須知',

		Home_u: '積分商城所列商品均由協力廠商供應商提供,不支持無理由退貨,但如有禮品發錯,快遞過程中損壞等情况,可聯系您的專屬客服給予解决。',

		Home_v: '人氣推薦',

		Home_w: '潮流商品大牌雲集,來InterstellarFX積分兌換,最受歡迎的潮流商品都在這裡等您',

		Home_x: '立即兌換',

		Home_y: '積分',

		Home_z: '精選品牌',

		Home_aa: '新品排列',

		Home_bb: '積分排序',

		Home_cc: '精選品牌',

		Home_dd: '僅需',

		Home_ee: '積分即可兌換',

		Home_ff: '請輸入禮品品牌',

		Home_gg: '請輸入商品描述',

		Home_hh: '成功',

		Home_hh: '條款',

		Home_ii: '成功',

		Home_zz: '星際積分商城',

		Home_kk: '積分商城',

		Home_search: '搜尋'

	},

	detail: {

		detail_a: '返回商城首頁',

		detail_b: '型號',

		detail_c: '數量',

		detail_d: '禮品兌換須知',

		detail_e: '兌換申請稽核通過後15天內禮品將會進行配送,請注意查收,您也可聯系您專屬客服經理査詢配送進度。',

		detail_f: '積分商城所列商品均由協力廠商供應商提供,不支持無理由退貨,但如有禮品發錯,快遞過程中損壞等情况,可聯系您的專屬客服給予解决。',

		detail_g: '商品詳情',

		detail_h: '人氣推薦',

		detail_i: '請登入',

	},

	settlement: {

		balance: '積分餘額',

		settlement_a: '訂單資訊',

		settlement_b: '名稱',

		settlement_c: '規格',

		settlement_d: '單價',

		settlement_e: '數量',

		settlement_f: '收貨人資訊',

		settlement_g: '添加地址',

		settlement_h: '應付金額',

		settlement_i: '積分',

		settlement_z: '立即提交',

		settlement_k: '提交成功',

		settlement_l: '請確認您的訂單！',

		settlement_m: '商品',
		settlement_n: '商品資訊！',
		settlement_o: '積分資訊！',
		settlement_p: '當前所需積分不足',

		settlement_title: '兌換成功！',

		settlement_tip: '您的兌換訂單將在15個工作日內進行禮品配送,您也可聯系您的專屬客服經理査詢配送進度。',

		settlement_button: '確定',

		settlement_tip_error: '積分獲取管道：交易貨幣對、貴金屬及能源產品,每交易1手,即可獲得1積分（持倉三分鐘以上訂單視為有效）',

	},

	meber: {

		meber_a: '訂單中心',

		meber_b: '個人中心',

		meber_c: '我的訂單',

		meber_d: '地址管理',

		meber_e: '登出',

	},



	address: {

		address_a: '新增',

		address_b: '姓名',

		address_c: '電話',

		address_d: '地址',

		address_e: '操作',

		address_f: '編輯',

		address_g: '删除',

		address_h: '請選擇',

		address_i: '詳細地址',

		address_j: '保存',

		address_k: '請輸入姓名',

		address_l: '請輸入手機號',

		address_m: '手機號格式不正確',

		address_n: '請輸入手機號',

		address_o: '請輸入詳細地址',

		address_p: '修改成功',

		address_q: '新增成功',

		address_r: '删除成功',

		address_s: '新增收貨地址',

		address_t: '您還未添加收貨地址',

	},



	order: {

		order_a: '我的訂單',

		order_b: '日期',

		order_c: '訂單號',

		order_d: '收件人',

		order_e: '金額',

		order_f: '訂單詳情',

		order_g: '電話',

		order_h: '地址',

		order_i: '狀態',

		order_j: '配送方式',

		order_k: '操作',

		order_l: '已關閉',

		order_m: '已創建',

		order_n: '已支付',

		order_o: '退款中',

		order_p: '已退款',

		order_q: '已發貨',

		order_r: '已完成',

		order_s: '花費積分',



	},



	userinfo: {

		userinfo_a: '個人中心',

		userinfo_b: '最後更新時間',

		userinfo_c: '用戶郵箱',

		userinfo_d: '可用積分',

	},



	orderDetail: {

		orderDetail_a: '提交訂單',

		orderDetail_b: '付款成功',

		orderDetail_c: '商品出庫',

		orderDetail_d: '等待收貨',

		orderDetail_e: '完成',

		orderDetail_f: '支付方式',

		orderDetail_g: '配送方式',

		orderDetail_h: '快遞單號'

	},



	footerbox: {

		footerbox_b: '今天的全球市場',

		footerbox_c: '嘗試一個免費的演示',

		footerbox_d: '亞太區客服（新加坡）',

		footerbox_e: '公司郵箱',

		footerbox_f: 'support@interstellarsfx.com',



		footerbox_g: '開始交易',

		footerbox_h: '外匯',

		footerbox_i: '金屬',

		footerbox_j: '能源',

		footerbox_k: '指數',

		footerbox_a: '關於交易',



		footerbox_l: '帳戶',

		footerbox_m: '帳戶類型',

		footerbox_n: '出入金',

		footerbox_o: '開設真實帳戶',

		footerbox_p: '開設類比帳戶',



		footerbox_q: '交易工具',

		footerbox_r: 'MetaTrader4',

		footerbox_s: 'MAM',

		footerbox_t: 'Multiterminal',

		footerbox_u: '財經日報',

		footerbox_v: '外匯小算盘',

		footerbox_w: 'VPS',

		footerbox_x: '技術分析',



		footerbox_y: '交易學院',

		footerbox_z: '術語彙編',



		footerbox_aa: '關於星際',

		footerbox_bb: '新聞',

		footerbox_cc: '招賢納士',

		footerbox_dd: '聯繫我們',

		footerbox_ee: '合作夥伴',

		footerbox_ff: '常見問題',


		footerbox_mm: '免責聲明',

		footerbox_nn: `網站 www.interstellarsfx.com 由 First Interstellar Global Ltd．營運，該公司是一家根據塞席爾法律註冊成立的有限責任公司，註冊辦公地址為Room 9, Deenu's Building, Providence, Mahe, Seychelles. First Interstellar Global Ltd.．First Interstellar Global Ltd．是由塞席爾金融服務管理局授權和監管，監管號碼為SD127，因此 First Interstellar Global Ltd．並不屬於歐洲金融監管框架，金融工具市場指令 MiFID II 以及任何其他歐洲金融服務法規皆不適用於 First Interstellar Global Ltd. 提供的金融服務，亦無投資者補償計畫的規定。`,

		footerbox_gg: 'Interstellar FX不向某些管轄區的居民提供差價合約交易，其中包括美國、伊朗、加拿大、比利時和法國。',

		footerbox_hh: '風險警告：',

		footerbox_ii: '交易外匯和槓桿化金融品種具有高風險，可能導致您損失本金。您所承擔的虧損風險不應超過您的承受能力，請確保您完全理解其中所涉風險，交易槓桿化產品並不適合所有投資者。交易非槓桿產品（如股票）也涉及風險，因爲股票的價值可升可跌，這意味著您收回的資金可能低於您最初的投入。過去的表現並不能保證未來的結果。在交易前，請考慮您的經驗水平、投資目標，如有必要請尋求獨立財務建議。此外，客戶有責任需確保他/她所居住國的法律規定允許其使用星際品牌所提供的服務。',

		footerbox_zz: '風險聲明',

		footerbox_kk: '隱私政策',

		footerbox_ll: 'Copyright©2021InterstellarFX.Allrightsreserved.',

	}
};