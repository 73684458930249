<template>
	<div class="newbanner">
		<!-- pc -->
		<div class="newbanner_box">
		  <el-carousel :interval="5000" arrow="always">
			<el-carousel-item v-for="item in newbanner">
			  <div class="banner_carousel">
				<img :src="item.bg" style="width: 100%; object-fit: cover;" class="banner_carousel_pc">				
				<div class="banner-container flex a-center j-center ">
							<div class="banner_left" style="width: 50%;"></div>
							<div class="banner_centers">
								<!-- <h2>{{item.title1}}</h2> -->
								<!-- <h2>{{item.title2}}</h2> -->
								<!-- <p>{{item.title3}}</p> -->
							</div>
							<div class="banner_right_top">
								<h4>Global access </h4>
								<h4>Local expertise</h4>
							</div>
				</div>
			  </div>
			</el-carousel-item>
		  </el-carousel>	  
		</div>
		
		
		<!-- 手机 -->
		<div class="newbanner_mobile" style="display: none;">
		  <el-carousel :interval="5000" arrow="always">
			<el-carousel-item v-for="item in newbanner">
			  <div class="banner_carousel">
				<div class="banner_right_top">
				  	<h4>Global access </h4>
				  	<h4>Local expertise</h4>
				</div>
			  	<img :src="item.bgsj" style="width: 100%; object-fit: cover;">
			  	<div class="banner-container ">
					<div class="banner_centers">
						<!-- <h2>{{item.title1}}</h2> -->
						<h2>{{item.title2}}</h2>
						<p>{{item.title3}}</p>
					</div>
			  	</div>
			  </div>
			</el-carousel-item>
		  </el-carousel>
		</div>
		
		
	</div>
	
	
	
	

	
</template>


<script>
	export default {


		data() {
		  return {				
            newbanner: [
				{
              bg: require('../assets/img/new_banner_01.png'),
			  bgsj: require('../assets/img/newbanner_mobile01.png'),
			  title1: this.$t('Home.Home_a'),
			  title2: this.$t('Home.Home_b'),
			  title3: this.$t('Home.Home_c'),
              }, 
			 //  {
    //           bg: require('../assets/img/new_banner_01.png'),
			 //  bgsj: require('../assets/img/newbanner_mobile01.png'),
			 //  title1: this.$t('Home.Home_a'),
			 //  title2: this.$t('Home.Home_b'),
			 //  title3: this.$t('Home.Home_c'),
    //           }, 
			 //  {
    //           bg: require('../assets/img/new_banner_01.png'),
			 //  bgsj: require('../assets/img/newbanner_mobile01.png'),
			 //  title1: this.$t('Home.Home_a'),
			 //  title2: this.$t('Home.Home_b'),
			 //  title3: this.$t('Home.Home_c'),
				// }
			]
		  }
		},
		
		methods: {

		},
		
		mounted() {
			
		}
	}
</script>

<style scoped>
/* 	  .el-carousel__item h3 {
	    color: #475669;
	    font-size: 18px;
	    opacity: 0.75;
	    line-height: 300px;
	    margin: 0;
	  }
	  
	  .el-carousel__item:nth-child(2n) {
	    background-color: #99a9bf;
	  }
	  
	  .el-carousel__item:nth-child(2n+1) {
	    background-color: #d3dce6;
	  }
	  */
	  .newbanner_box {
	  	width: 100%;
	  	height: 100%;
	  	position: relative;
	  }
	  
	  .banner_carousel{width: 100%; height:100%; background: #000; position: relative;}
	  
	  .banner_carousel img{ width: 100%; height: 100%;}
	  
	  .banner-container{
	  	    width: 1140px;
	  	    position: absolute;
	  	    left: 50%;
	  	    top: 0%;
	  	    transform: translateX(-50%);
	  	    height: auto;
	  }
	  
	  
	  .banner_centers{
		      margin-top: 20%;
			  margin-left: 40%;
	  }
	  	
	  .banner_centers h2{ 
	  	    color: #fff;
	  	    font-size: 32px;
	  	    line-height: 38px;
	  }
	  	
	  .banner_centers p{
	  	    color: #fff;
	  	    font-size: 16px;
	  	    width: 390px;
	  	    margin-top: 30px;
	  }
	  
	  .banner_right_top{
	  	    position: absolute;
	  	    text-align: right;
	  	    color: rgb(255 255 255 / 75%);
	  	    top: 12px;
	  	    right: 12px;
	  
	  }
	  
	 .banner_right_top h4{
	  		font-size: 16px;
	  		font-weight: normal;
	 }
	 
	 /deep/.newbanner_box .el-carousel__arrow{ display: none;}
	 
	 /deep/.newbanner_box .el-carousel__indicators {
	     display: none;
	 }
	 
	 /* 兼容平板 */
	  @media screen and (min-width:751px) and (max-width:1160px){
		  
		  .banner{ height: 500px;}
		  .banner_box{width: 100%;}
		  .banner_left{left: 4%;}
		  .banner_right_top{right: 2% !important;     top: 6%;}
		  .banner_right_bottom{right: 4%;}
		  .banner_left h2[data-v-9ea40744] {
		  	color: #fff;
		  	padding-bottom: 10px;
		  	font-size: 22px !important;
		  	width: 70%;
		  }
		  .banner_left p {
		      color: #d7dbde;
		      font-size: 16px;
		      width: 70%;
		      line-height: 30px;
		  }
		  .banner-container{
				width: 100%;
				position: absolute;
				left: 50%;
				top: 0%;
				transform: translateX(-50%);
				height: auto;
		 }
			
		 .banner_centers{
				margin-top: 20%;
				
		 }
		 
		
	 }
	 
	 /* 兼容手机 */
	 @media screen and (max-width: 750px) {
		 .newbanner_box{ display: none;}
		 
		 .newbanner_mobile{ 
		 	display: block !important;
		 	width: 100%;
		 	height: 100%;
		 	position: relative;
		}
		 	
		/deep/ .newbanner_mobile .el-carousel__container {
			min-height: 450px !important;
		} 
		
		.newbanner_mobile .banner_carousel img {
		    width: 100%;
		    height: 310px;
		}
		.banner_carousel {
		    background: rgb(33 37 41 / 50%);
		}
		
		.banner_centers{
		    margin-top: 0;
			margin-left: 0;
		}
		
		.banner-container {
		    width: 100%;
		    position: absolute;
		    left: 0;
		    top: auto;
			bottom: 0;
		    transform: none;
		    height: auto;
		    padding: 3%;
			background: #909294;
		}
		
		.banner_right_top{
		    position: absolute;
		    text-align: right;
		    color: rgb(255 255 255 / 75%);
		    top: 2%;
		    right: 0;
		}
		
		.banner_centers h2 {
		    color: #fff;
		    font-size: 22px;
		    line-height: 29px;
		}
		
		.banner_centers p{
		    color: #fff;
		    font-size: 16px;
		    width: auto;
		    margin-top: 12px;
		}
		
       /deep/ .newbanner_mobile .el-carousel__arrow {
		    top: 93%;
			display: none;
		}
		
		/deep/.newbanner_mobile .el-carousel__indicators--horizontal{ display: none;}
	 }
</style>