import API from './server.js'
export default {
	denglu(p) {//登录
		return API.POST('/login', p)
	},
	resetbox(p) {//提交重置密码申请
		return API.POST('/user/reset', p)
	},
	resetpwd(p) {//提交重置密码申请
		return API.POST('/user/resetpwd', p)
	},
	userinfo(p) {//获取用户信息
		return API.GET('/user/info', p)
	},
	goodsList(p){//获取商品列表
		return API.GET('/goods/list', p)
	},
	orderList(p){//查询订单列表
		return API.GET('/order', p)
	},
	queryAddress(p) {
		return API.GET('/address', p)
	},
	
	addAddress(p) {
		return API.POST('/address', p)
	},
	
	delAddress(p) {
		return API.DELETE('/address', p)
	},
	
	putAddress(p) {
		return API.PUT('/address', p)
	},
	goodsClass(p){
		return API.GET('/goods/class', p)
	},
	country(p){
		return API.GET('/country', p)
	},
	countryDetail(p){
		return API.GET('/country/detail', p)
	},
	goodsinfo(p){
		return API.GET('/goods/info/'+p)
	},
	goodspec(p){
		return API.GET('/goods/spec/'+p)
	},
	indexstar(p){
		return API.GET('/goods/list/index/star',p)
	},
	goodslike(p){
		return API.POST('/goods/like',p)
	},
	orderbox(p){
		return API.POST('/order',p)
	},
	goodsbrand(p){
		return API.GET('/goods/brand',p)
	},
	orderdetail(p){
		return API.GET('/order/detail',p)
	},
	// 
	// /user/reset
}
