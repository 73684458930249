//英文
export default {
	tip:{
		fromSuccess:'Submitted successfully'
	},
	login:{
	  linkList:[{name:'Star Credits Mall',url:'/'},{name:'Register as a trader',url:'https://tw.fisg.com/register/trader?link_id=ct01egz0&referrer_id=v4w8jplv'}],
	  registered:{
	  		text:"Don't have an account? Click Register >>",
	  		url:'https://tw.fisg.com/register/trader?link_id=ct01egz0&referrer_id=v4w8jplv'
	  },
	  shouye:{
	  		url:'/'
	  },
	  forgot:'Forgot password？',
	  title:'The account login',
	  email:'Mailbox',
	  pwd:'Password',
	  denglu:'Sign in',
	  mima:'Forget password',
	  Reset: 'Reset password',
	  p_email: 'Please enter email',
	  p_pwd: 'Please input a password',
	  p_new_pwd: 'Please enter your password',
	  z_email: 'Please enter the correct email address',
	  long:'6 to 18 characters in length',
	  determine:'Determine',
	  Valid:'The password reset application has been submitted. Please check your email and check the reset link. The link is valid within 24 hours!',
	  Help_reset:'Enter your registered email address and we will help you reset your password.'
	 },
	myhead:{
		loginName: 'Login',
		
		Personalcenter:'Personal center',

		Placeholder :' Please enter... ',

		myhead_a: 'Home',

		myhead_b: 'Trading',

		myhead_c: 'Platform',

		myhead_d :' Trade School ',

		myhead_e :' Company ',
		
		myhead_f:'Interstellarfx Assist you',
		myhead_g:'Be the best trader',
		myhead_h:'Start Trading',
		myhead_i:'Try a free demo',
		myhead_j:{
		
		text:'Market activity',
		
		url:'https://www.interstellarfx.net/zh/trade-cashback'
		
		},
		myhead_k:'Transaction cash back',
		myhead_l:'Markets',
		myhead_m:'Trade related',
		myhead_n:'Foreign exchange school',
		myhead_o:'Mid-Autumn Festival Points Rewards Campaign',
		myhead_p:'Notice of InterStellar',
		myhead_q:'Market News',
		
		myhead_r:'As a complicated financial trading product, contracts for difference (CFDs) have the high risk of rapid loss arising from its leverage feature. Most retail investor accounts recorded fund loss in contracts for differences. You should consider whether you have developed a full understanding about the operation rules of contracts for differences and whether you can bear the high risk of fund loss.',
		myhead_s:'Company',
		myhead_t:'Markets',
		myhead_u:'Account',
		myhead_v:'Analysis and Education',
		myhead_v_a:' Analysis',
		myhead_v_b:' Education',
		myhead_w:'Supervision',
		myhead_x:'Insurance',
		myhead_y:'Legal Compliance',
		myhead_z:'Latest news',
		myhead_aa:'Trading Guide',
		myhead_bb:'Glossary of Trading Terms',	
		myhead_cc:'Breaking News',
		myhead_dd:'Transaction Analysis',
		myhead_ee:'Forex Investment',
		myhead_ff:'Sponsorship & Social Responsibility',
		
	},
	Home:{
		Home_a:' Star Points Mall ',

		Home_b:' Trade points for gifts! ',

		Home_c:' Trade points for good gifts, fashion digital, exquisite electrical appliances, top luxury cars and many other good things to take home at any time... ',

		Home_d:' View activity terms & regulations apply ',

		Home_e: 'Click',

		Home_f: 'Here',

		Home_g:' Good gift, waiting for you ',

		Home_h:' No gift you want? ',

		Home_i:' You are welcome to recommend the products you want to us at any time, we will supplement the products according to the popularity of the recommended gift... ',

		Home_j:' Gift brand',

		Home_k:' Commodity description ',

		Home_l: 'Submission',

		Home_m:' Bonus point Gift exchange Rules ',

		Home_n:' How to get points ',

		Home_o:' Any Interstellar FX customer who trades currency pairs, precious metals and energy products on our platform will get 1 credit for each trade. ',

		Home_p:' How to exchange gifts ',

		Home_q:' Submit the exchange application in the points mall (fill in your name, contact information, and trading account number and other information), and the background will check your points and whether there are any recent illegal transactions, and distribute them after passing the review. ',

		Home_r:' Gift delivery time ',

		Home_s:' The gift will be delivered within 15 days after the exchange application is approved, please note that check, you can also contact your own customer service manager to check the delivery progress. ',

		Home_t:' After sale instructions ',

		Home_u:' All the goods listed in the integral mall are provided by third-party suppliers, and no unreasonable return is supported. However, if there is a wrong gift, or damage in the process of express delivery, you can contact your exclusive customer service to solve the problem. ',

		Home_v:' popular recommendation ',

		Home_w:' The most popular trend goods are waiting for you here ',

		Home_x:' Convert ',

		Home_y:' Points',
		
		Home_z:'Select brands ',

		Home_aa:'New product array ',

		Home_bb:'Points sort ',

		Home_cc:'Select brands ',

		Home_dd:'Only',

		Home_ee:'Redeemable points ',

		Home_ff:'Please enter the gift brand ',

		Home_gg:'Please enter product description ',

		Home_hh:'Success',

		Home_hh:'Terms',
		
		Home_ii:'Success',
		
		Home_zz:'Integral mall',
		
		Home_kk:'Points Mall',

		Home_search:'Search'

	},
	detail:{
		detail_a :' Return to mall home page ',

		detail_b: 'Models',

		detail_c: 'Number',

		detail_d :' Gift Exchange Instructions ',

		detail_e :' The gift will be delivered within 15 days after the approval of the exchange application, please note that check, you can also contact your dedicated customer service manager to check the delivery progress. ',

		detail_f :' All the goods listed in the Integrpoint mall are provided by the third party supplier, and returns without reason are not supported. However, if there is a wrong gift or damage in the process of express delivery, you can contact your exclusive customer service to solve the problem. ',

		detail_g :' Product details ',

		detail_h :' Popular recommendation ',

		detail_i :' Please log in ',
		
		address_s:'New harvest address',
	},
	settlement:{
		balance:'Points balance',
		
		settlement_a:'Order Information ',

		settlement_b:'Name',

		settlement_c:'Specification',

		settlement_d:'The unit price',

		settlement_e:'Number',

		settlement_f:'Consignee information ',

		settlement_g:'Add address ',

		settlement_h:'Settlement amount ',

		settlement_i:'Points',

		settlement_z:' Settle immediately ',

		settlement_k:' Commit successfully ',
		
		settlement_l:'Please confirm your order!',
		
		settlement_m:'Product',
		
		settlement_n:'Product information',	
		
		settlement_o:'Point information!',	
				
		settlement_p:'The current required points are insufficient',	
		
		settlement_title:'For successful ！',
		settlement_tip:'Your exchange order will be delivered within 15 working days. You can also contact your dedicated customer service manager to check the delivery progress.',
		settlement_button:'determine',
		settlement_tip_error:'Points acquisition method: Trading currency pairs, precious metals, and energy products, you can earn 1 point for each transaction (orders with a position of more than three minutes are considered valid)',
	},
	meber:{
		meber_a :' Order center ',

		meber_b :' Personal center ',

		meber_c :' My order ',

		meber_d :' Address management ',

		meber_e :' Log out ',
	},	
	
	address:{
	
		address_a: 'Increase',
		
		address_b: 'Name',
		
		address_c: 'Call',
		
		address_d: 'Address',
		
		address_e: 'Operation',
		
		address_f: 'Editing',
		
		address_g: 'Delete',
		
		address_h :' Please select ',
		
		address_i :' Detailed address ',
		
		address_j: 'Save',
		
		address_k :' Enter your name ',
		
		address_l :' Please enter your phone number ',
		
		address_m :' Phone number format is incorrect ',
		
		address_n :' Please enter your phone number ',
		
		address_o :' Please enter full address ',
		
		address_p :' Modified successfully ',
		
		address_q :' Add succeeded ',
		
		address_r :' Delete succeeded ',
		
		address_s:'New harvest address',
		
		address_t:'You have not added a shipping address',
		
		},
		order:{
			order_a :' My order ',

			order_b: 'Date',

			order_c :' Order number ',

			order_d :' Recipient ',

			order_e: 'Amount',

			order_f :' Order details ',

			order_g: 'Call',

			order_h: 'Address',

			order_i: 'State',

			order_j :' Shipping mode ',

			order_k: 'Operation',

			order_l :' Closed ',

			order_m :' Created ',

			order_n :' Paid ',

			order_o :' Refund in progress ',

			order_p :' Refunded ',

			order_q :' Shipped ',

			order_r :' Finished ',
			
			order_s:'Cost integral',
		},
		
		userinfo:{
			userinfo_a: 'Personal center',

			userinfo_b :' last update time ',

			userinfo_c :' User mailbox ',

			userinfo_d :' Credits available ',
		},
		
		orderDetail:{
			orderDetail_a :' Submit order ',

			orderDetail_b :' Payment succeeded ',

			orderDetail_c :' Item out ',

			orderDetail_d :' Waiting for delivery ',

			orderDetail_e: 'Complete',

			orderDetail_f :' Payment method ',

			orderDetail_g :' Courier name ',

			orderDetail_h :' Tracking number '
		},
		
		footerbox:{
			footerbox_b:"Today's global market",
			footerbox_c:'TRY A FREE DEMO',
			footerbox_d:'Asia Pacific Customer Service（Singapore）',
			footerbox_e:'Company Email',
			footerbox_f:'support@interstellarsfx.com',
			
			footerbox_g:'Get Started',
			footerbox_h:'Forex',
			footerbox_i:'Metals',
			footerbox_j:'Energies',
			footerbox_k:'Indices',
			footerbox_a:'About the trading',
			
			footerbox_l:'Account',
			footerbox_m:'Types of Accounts',
			footerbox_n:'Deposit and Withdrawal',		
			footerbox_o:'Start Trading',
			footerbox_p:'Try a free demo ',
			
			footerbox_q:'Trading Tools',
			footerbox_r:'MetaTrader 4',
			footerbox_s:'MAM',		
			footerbox_t:'Multiterminal',
			footerbox_u:'Economic Calendar',
			footerbox_v:'Forex Calculator',
			footerbox_w:'VPS',
			footerbox_x:'Trading Central',
			
			footerbox_y:'Trade School',
			footerbox_z:'The Term Assembly',
			
			footerbox_aa:'About InterStellar',
			footerbox_bb:'News',
			footerbox_cc:'Careers',		
			footerbox_dd:'Contact Us',
			footerbox_ee:'Partners',
			footerbox_ff:'FAQ',
			
			footerbox_mm:'Disclaimer: ',
			footerbox_nn:`The website www.interstellarsfx.com is operated by First Interstellar Global Ltd, a limited liability company incorporated under the laws of Seychelles, with registration number [insert Company’s registration number] and with registered office address at Room 9, Deenu's Building, Providence, Mahe, Seychelles.First Interstellar Global Ltd is authorized and regulated by the Financial Services Authority of Seychelles with license no. SD127. First Interstellar Global Ltd is not part of the European financial regulatory framework. The Markets in Financial Instruments Directive (MiFID) II, as well as any other European financial services regulation, is not applicable to the provision of financial services by First Interstellar Global Ltd. In addition, there is no provision for an investor compensation scheme.Disclaimer: The website www.interstellarsfx.com is operated by First Interstellar Global Ltd, a limited liability company incorporated under the laws of Seychelles, with registration number [insert Company’s registration number] and with registered office address at Room 9, Deenu's Building, Providence, Mahe, Seychelles.First Interstellar Global Ltd is authorized and regulated by the Financial Services Authority of Seychelles with license no. SD127. First Interstellar Global Ltd is not part of the European financial regulatory framework. The Markets in Financial Instruments Directive (MiFID) II, as well as any other European financial services regulation, is not applicable to the provision of financial services by First Interstellar Global Ltd. In addition, there is no provision for an investor compensation scheme.`,
			footerbox_gg:'InterstellarFX does not offer CFDs to residents of certain jurisdictions, including the United States, Iran, Canada, Belgium and France.',
			footerbox_hh:'Risk Warning:',
			footerbox_ii:`Trading foreign exchange and leveraged financial products has high risks and may cause you to lose your principal. You should not take the risk of loss more than you can afford and please ensure that you fully understand the risks involved. Trading leveraged products is not suitable for all investors. Trading unleveraged products such as shares also involves risk as the value of shares can go down as well as up, meaning you may get back less money than you originally invested. Past performance is no guarantee of future results. Before trading, please consider your level of experience, investment objectives and seek independent financial advice if necessary. It is the customer's responsibility to ensure that the legal requirements of his/her country of residence allow him/her to use the services provided by the StarWorld brand.`,		
			footerbox_zz:'Risk Statement',
			footerbox_kk:'Privacy Policy',
			footerbox_ll:'Copyright © 2021 Interstellar FX. All rights reserved.',
		}
	

}