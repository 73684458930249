//中文
export default {
	tip: {
		fromSuccess: '提交成功'
	},
	login: {
		linkList: [{ name: '星际积分商城', url: '/' }, { name: '注册成为交易者', url: 'https://tw.fisg.com/register/trader?link_id=ct01egz0&referrer_id=v4w8jplv' }],
		registered: {
			text: '没有帐户？点击注册>>',
			url: 'https://tw.fisg.com/register/trader?link_id=ct01egz0&referrer_id=v4w8jplv',
		},
		shouye: {
			url: '/'
		},
		forgot: '忘记密码？',
		title: '账户登录',
		email: '邮箱',
		pwd: '密码',
		denglu: '登录',
		mima: '忘记密码',
		Reset: '重置密码',
		p_email: '请输入邮箱',
		p_pwd: '请输入密码',
		p_new_pwd: '请输新入密码',
		z_email: '请输入正确的邮箱地址',
		long: '长度在 6 到 18个字符',
		determine: '确认',
		Valid: '重置密码申请已提交，请检查您的邮箱查收重置链接，链接24小时内有效！',
		Help_reset: '输入您注册的电子邮箱，我们会帮助您重置密码。'
	},
	myhead: {
		loginName: '登录',
		Personalcenter: '个人中心',
		placeholder: '请输入...',
		myhead_a: '首页',
		myhead_b: '交易',
		myhead_c: '平台',
		myhead_d: '交易学院',
		myhead_e: '关于我们',
		myhead_f: '星际助力您',
		myhead_g: '成为最佳交易者',
		myhead_h: '开设真实账户',
		myhead_i: '开始模拟账户',
		myhead_j: {
			text: '市场活动',
			url: 'https://www.interstellarfx.net/zh/trade-cashback'
		},
		myhead_k: '交易返现',
		myhead_l: '交易产品',
		myhead_m: '交易相关',
		myhead_n: '外汇学堂',
		myhead_o: '中秋积分奖励活动',
		myhead_p: '星际公告',
		myhead_q: '市场资讯',


		myhead_r: '差价合约是复杂的金融交易产品，可带来由杠杆作用而导致迅速亏损的高风险。大部分的零售投资者帐户在跟交易差价合约时录得资金亏损。您应当考虑您是否充分明白差价合约如何运作，以及您是否能承受您的资金损失的高风险。',
		myhead_s: '公司',
		myhead_t: '市场',
		myhead_u: '报价',
		myhead_v: '分析和教育',
		myhead_v_a: '分析',
		myhead_v_b: '教育',
		myhead_w: '监管',
		myhead_x: '保险',
		myhead_y: '法律合规',
		myhead_z: '最新动态',
		myhead_aa: '交易指南',
		myhead_bb: '交易术语表',
		myhead_cc: '实时快讯',
		myhead_dd: '交易分析',
		myhead_ee: '外汇投资策略',
		myhead_ff: '活动赞助 & 社会责任',


	},
	Home: {
		Home_a: ' 星际积分商城 ',
		Home_b: '交易得积分，积分换豪礼 ！',
		Home_c: '交易积分兑换好礼，时尚数码、精致电器、顶级豪车等众多好物随时带回家...',
		Home_d: '查看活动条款&条例适用 ',
		Home_e: '点击',
		Home_f: '这里',
		Home_g: '缤纷好礼，就等你来',
		Home_h: '没有您想要的礼品？',
		Home_i: '欢迎您随时推荐想要的商品给我们，我们将根据推荐礼品的受欢迎度补充商品...',
		Home_j: '礼品品牌：如小米、华为...',
		Home_k: '商品描述：如小米电动plus九号平衡车...',
		Home_l: '提交',
		Home_m: '积分礼品兑换规则',
		Home_n: '积分获取方式',
		Home_o: '任何 Interstellar FX 客户在我们的平台交易货币对、贵金属及能源产品，每交易1手，即可获得1积分（持仓三分钟以上订单视为有效）。',
		Home_p: '礼品如何兑换',
		Home_q: '在积分商城提交兑换申请（须填写姓名、联系方式、及交易账号等信息），后台将审核您积分及近期有无违规交易行为,审核通过后进行配送。',
		Home_r: '礼品配送时间',
		Home_s: '兑换申请审核通过后15天内礼品将会进行配送，请注意查收，您也可联系您专属客服经理查询配送进度。',
		Home_t: '商品售后须知',
		Home_u: '积分商城所列商品均由第三方供应商提供，不支持无理由退货，但如有礼品发错，快递过程中损坏等情况，可联系您的专属客服给予解决。',
		Home_v: '人气推荐',
		Home_w: '潮流商品大牌云集，来Interstellar FX积分兑换，最受欢迎的潮流商品都在这里等您',
		Home_x: '立即兑换',
		Home_y: '积分',
		Home_z: '精选品牌',
		Home_aa: '新品排列',
		Home_bb: '积分排序',
		Home_cc: '精选品牌',
		Home_dd: '仅需',
		Home_ee: '积分即可兑换',
		Home_ff: '请输入礼品品牌',
		Home_gg: '请输入商品描述',
		Home_hh: '成功',
		Home_hh: '条款',
		Home_ii: '成功',
		Home_zz: '星际积分商城',
		Home_kk: '积分商城',
		Home_search: '搜索商品'
	},
	detail: {
		detail_a: '返回商城首页',
		detail_b: '型号',
		detail_c: '数量',
		detail_d: '礼品兑换须知',
		detail_e: '兑换申请审核通过后15天内礼品将会进行配送，请注意查收，您也可联系您专属客服经理查询配送进度。',
		detail_f: '积分商城所列商品均由第三方供应商提供，不支持无理由退货，但如有礼品发错，快递过程中损坏等情况，可联系您的专属客服给予解决。',
		detail_g: '商品详情',
		detail_h: '人气推荐',
		detail_i: '请登录',
	},
	settlement: {
		balance: '积分余额',
		settlement_a: '订单信息',
		settlement_b: '名称',
		settlement_c: '规格',
		settlement_d: '单价',
		settlement_e: '数量',
		settlement_f: '收货人信息',
		settlement_g: '添加地址',
		settlement_h: '应付金额',
		settlement_i: '积分',
		settlement_z: '立即提交',
		settlement_k: '提交成功',
		settlement_l: '请确认您的订单!',
		settlement_m: '商品',
		settlement_n: '商品信息!',
		settlement_o: '积分信息!',
		settlement_p: '当前所需积分不足',
		settlement_title: '兑换成功！',
		settlement_tip: '您的兑换订单将在15个工作日内进行礼品配送，您也可联系您的专属客服经理查询配送进度。',
		settlement_button: '确定',
		settlement_tip_error: '积分获取方式：交易货币对、贵金属及能源产品，每交易1手，即可获得1积分（持仓三分钟以上订单视为有效）',

	},
	meber: {
		meber_a: '订单中心',
		meber_b: '个人中心',
		meber_c: '我的订单',
		meber_d: '地址管理',
		meber_e: '退出登录',
	},

	address: {
		address_a: '增加',
		address_b: '姓名',
		address_c: '电话',
		address_d: '地址',
		address_e: '操作',
		address_f: '编辑',
		address_g: '删除',
		address_h: '请选择',
		address_i: '详细地址',
		address_j: '保存',
		address_k: '请输入姓名',
		address_l: '请输入手机号',
		address_m: '手机号格式不正确',
		address_n: '请输入手机号',
		address_o: '请输入详细地址',
		address_p: '修改成功',
		address_q: '新增成功',
		address_r: '删除成功',
		address_s: '新增收货地址',
		address_t: '您还未添加收货地址',
	},

	order: {
		order_a: '我的订单',
		order_b: '日期',
		order_c: '订单号',
		order_d: '收件人',
		order_e: '金额',
		order_f: '订单详情',
		order_g: '电话',
		order_h: '地址',
		order_i: '状态',
		order_j: '配送方式',
		order_k: '操作',
		order_l: '已关闭',
		order_m: '已创建',
		order_n: '已支付',
		order_o: '退款中',
		order_p: '已退款',
		order_q: '已发货',
		order_r: '已完成',
		order_s: '花费积分',

	},

	userinfo: {
		userinfo_a: '个人中心',
		userinfo_b: '最后更新时间',
		userinfo_c: '用户邮箱',
		userinfo_d: '可用积分',
	},

	orderDetail: {
		orderDetail_a: '提交订单',
		orderDetail_b: '付款成功',
		orderDetail_c: '商品出库',
		orderDetail_d: '等待收货',
		orderDetail_e: '完成',
		orderDetail_f: '支付方式',
		orderDetail_g: '配送方式',
		orderDetail_h: '快递单号'
	},

	footerbox: {
		footerbox_b: '今天的全球市场',
		footerbox_c: '尝试一个免费的演示',
		footerbox_d: '亚太区客服（新加坡 ）',
		footerbox_e: '公司邮箱',
		footerbox_f: 'support@interstellarsfx.com',

		footerbox_g: '开始交易',
		footerbox_h: '外汇',
		footerbox_i: '金属',
		footerbox_j: '能源',
		footerbox_k: '指数',
		footerbox_a: '关于交易',

		footerbox_l: '账户',
		footerbox_m: '账户类型',
		footerbox_n: '出入金',
		footerbox_o: '开设真实账户',
		footerbox_p: '开设模拟账户 ',

		footerbox_q: '交易工具',
		footerbox_r: 'MetaTrader 4',
		footerbox_s: 'MAM',
		footerbox_t: 'Multiterminal',
		footerbox_u: '财经日历',
		footerbox_v: '外汇计算器',
		footerbox_w: 'VPS',
		footerbox_x: '技术分析',

		footerbox_y: '交易学院',
		footerbox_z: '交易术语表',

		footerbox_aa: '关于星际',
		footerbox_bb: '新闻',
		footerbox_cc: '招贤纳士',
		footerbox_dd: '联系我们',
		footerbox_ee: '合作伙伴',
		footerbox_ff: '常见问题',

		footerbox_mm: '免责声明: ',
		footerbox_nn: `网站 www.interstellarsfx.com 由 First Interstellar Global Ltd 运营，该公司是一家根据塞舌尔法律注册成立的有限责任公司，注册办公地址为Room 9, Deenu's Building, Providence, Mahe, Seychelles。First Interstellar Global Ltd 是由塞舌尔金融服务管理局授权和监管，监管号为SD127。 First Interstellar Global Ltd 不属于欧洲金融监管框架，金融工具市场指令 (MiFID) II 以及任何其他欧洲金融服务法规不适用于 First Interstellar Global Ltd. 提供的金融服务，没有投资者补偿计划的规定。`,
		footerbox_gg: 'InterstellarFX不向某些管辖区的居民提供差价合约交易，其中包括美国、伊朗、加拿大、比利时和法国。',
		footerbox_hh: '风险警告：',
		footerbox_ii: '交易外汇和杠杆化金融品种具有高风险，可能导致您损失本金。您所承担的亏损风险不应超过您的承受能力，请确保您完全理解其中所涉风险。交易杠杆化产品并不适合所有投资者。交易非杠杆产品（如股票）也涉及风险，因为股票的价值可升可跌，这意味着您收回的资金可能低于您最初的投入。过去的表现并不能保证未来的结果。在交易前，请考虑您的经验水平、投资目标，如有必要请寻求独立财务建议。客户有责任确保他/她所居住国法律要求允许其使用星际品牌提供的服务。',
		footerbox_zz: '风险声明',
		footerbox_kk: '隐私政策',
		footerbox_ll: 'Copyright © 2021 Interstellar FX. All rights reserved.',
	}

};